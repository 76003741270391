/* You can add global styles to this file, and also import other style files */
* {
  font-family: 'Lato', sans-serif;
}

.header {
  font-family: 'Bebas Neue', cursive;
}

.header-1 {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
}

.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
}

.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
}

.grid-gap-1 {
  gap: 1%;
}

.grid-gap-2 {
  gap: 2%;
}

.font-s4 {
  font-size: 24px;
  font-family: 'Bebas Neue', cursive;
}

.font-s5 {
  font-size: 16px;
}

.font-s6 {
  font-size: 14px;
}

.color-primary {
  color: rgb(0, 140, 255);
}

.color-danger {
  color: orangered;
}

.color-warning {
  color: rgb(158, 142, 0);
}

.color-succeed {
  color: rgb(0, 128, 68);
}

.line-height-1 {
  line-height: 1;
}

.scroll {
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.relative {
  position: relative;
}

@media (min-width: 768px) {
  .container {
    padding: 0;
  }
}
